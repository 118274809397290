.navbar {
  height: 80px;
}

.bg-navbar {
  background: #00b544;
}

.bg-navbar {
  background: linear-gradient(to right, #00e96a, #00b544);
}

.bg-custom {
  background: #fff;
}

.nav-text {
  font-weight: bold;
}

.btn.btn-primary {
  background-color: #00e96a;
  border: none;
  padding: 15px 25px;
  font-size: 12pt;
  font-weight: bold;
  color: #212529;
  border-radius: 0;
}

.btn.btn-primary:hover {
  background-color: #00d45b;
  border: none;
  padding: 15px 25px;
  font-size: 12pt;
  font-weight: bold;
  color: #212529;
  border-radius: 0;
}

.btn.btn-primary:active:focus {
  background-color: #00d45b;
  border: none;
  padding: 15px 25px;
  font-size: 12pt;
  font-weight: bold;
  color: #212529;
  border-radius: 0;
}

.title-header h3 {
  font-weight: bold;
  font-size: 20pt;
}

.text-header h6 {
  padding-left: 120px;
  padding-right: 120px;
  font-size: 14pt;
}

.form {
  background-color: #f1f3f5;
}

.img-arrow {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

span .btn {
  border: none !important;
}

#form h4 {
  font-weight: bold;
  font-size: 23pt;
}

#form label {
  font-weight: bold;
}

.custom-progress {
  background: #00b544 !important;
}

.bg-custom-info {
  background: #00b544 !important;
}

.bg-custom-info h2 {
  font-weight: 'bold';
  color: #fff;
}

.bg-custom-info h2 {
  font-weight: 'bold';
  color: #fff;
}

.bg-custom-info h5 {
  padding-top: 10px;
  font-weight: 'bold';
  color: #fff;
}

.bg-custom-info span {
  padding-top: 15px;
  font-size: '20pt';
  color: #fff;
}

.container-fluid h3 {
  font-weight: bold;
}

.container-fluid h5 {
  font-weight: bold;
}

.bg-custom-code {
  background: #f1f3f5 !important;
  color: #212529;
  border-radius: 1%;
}

.btn-custom-code {
  background: #00b544 !important;
  font-weight: bold !important;
  color: #fff !important;
  font-size: 11pt !important;
}

.img-copy {
  width: 24px;
  height: 20px;
  padding-left: 5px;
}

ul a {
  text-decoration: none;
}

footer img {
  padding-left: 10px;
}

.cust .inp-cls {
  border: none;
  border-bottom: 1px solid #eee;
  background: rgba(224, 228, 249, 0.1);
}

.cust .flag-dropdown {
  background: white;
  border: none;
  border-bottom: 1px solid #eee;
}

.cust .dropd .country-list {
  max-height: 300px;
}
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
.WA_Chat_Widget *{
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.WA_Chat_Widget .WA_FloatingButton{
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.185);
    border-radius: 50%;
    background: #4fce5d;
    cursor: pointer;
}
.WA_Chat_Widget .WA_FloatingButton img{
    width: 40px;
    height: 45px;
}

.WA_Chat_Widget .WA_ChatBox{
    width: 360px;
    height: auto;
    border-radius: 8px !important;
    background-color: #fff;
    box-shadow: 0 5px 25px 0px rgba(0, 0, 0, 0.1);
}

.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header{
    background: #00b544;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .avatarBox{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .avatarBox img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .infoBox .name{
    color: #fff;
    font-weight: 600;
    font-size: 15pt;
}

.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .WA_Close{
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-size: 20px;
    position: relative;
    top: -30px;
    right: -40px;
    cursor: pointer;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .WA_Close img{
    width: 20px;
    height: 20px;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body{
    padding: 20px;
    background: #e6ddd4;
    position: relative;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body::before{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    z-index: 0;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message{
    max-width: 280px;
    background: #fff;
    padding: 20px;
    padding-top: 10px;
    border-radius: 8px;
    z-index: 1;
    position: relative;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    left: -10px;
    top: 0px;
    border-radius: 3px;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message .username{
    color: darkgray;
    font-weight: 500;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message .message_content{
    font-size: 14px;
    color: #000;
}

.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Footer{
    padding: 20px;
    background-color: #fff;
    width: 100%;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Footer .btn{
    width: 100%;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 1000px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Footer .btn.btn-whatsapp{
    background: #00b544;
    color: #fff;
    display: block;
    text-align: center;
}